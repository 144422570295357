<template>
  <div :class="{ 'report': true, 'en-font': type == 'en', 'cn-font': type == 'cn' }" v-loading="loading">
    <web-header :language="type" ref="webHeaderRef"></web-header>
    <div class="report-wrap">
      <div :class="{ 'report-wrap-title': true, 'en-font-bold': type == 'en', 'cn-font-bold': type == 'cn' }">
        {{ info.product_name }}
      </div>
      <div class="report-wrap-opera" :style="{ padding: pdf_url ? '0' : '15px 0' }">
        <div :class="{ 'report-wrap-opera-text': true, 'roboto-light': type == 'en' }">
          <span v-if="type == 'cn'">{{ $moment(info.send_time).format("YYYY-MM-DD") }}</span>
          <span v-else>{{ $moment(info.send_time).format("LL") }}</span>
          <span v-if="info.author">{{ info.author }}</span>
        </div>
        <div class="report-wrap-opera-btn">
          <a :href="pdf_url" target="_blank" :class="{ 'report-wrap-opera-btn-download': true, 'roboto-light': type == 'en' }" v-if="pdf_url">
            <i class="iconfont icon-downloadPDF"></i>
            <span>{{ type == 'en' ? 'Download PDF' : '下载报告' }}</span>
          </a>
          <div class="report-wrap-opera-btn-oa">
            <div class="report-wrap-opera-btn-oa-lump" @click="printFn">
              <i class="iconfont icon-print"></i>
            </div>
            <div class="report-wrap-opera-btn-oa-lump" @click="copyUrl">
              <i class="iconfont icon-share"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="report-wrap-file" v-if="audioInfo.url && audioInfo.url.img">
        <div class="report-wrap-file-name">
          <span v-if="audioInfo.title">{{ type == 'en' ? "Audio" : "音频" }}：{{ audioInfo.title }}</span>
        </div>
        <audio controls="" controlslist="nodownload" :src="audioInfo.url.img"></audio>
      </div>
      <div class="report-wrap-video" v-if="videoInfo.url && videoInfo.url.img">
        <video controls :poster="videoInfo.img && videoInfo.img.img ? videoInfo.img.img : ''" preload="metadata">
          <source :src="videoInfo.url.img" type="video/mp4">
        </video>
      </div>
      <div class="report-wrap-content">
        <div class="report-wrap-content-box" id="printContent">
          <div class="report-wrap-content-box-substance" v-html="info.pdf_content" v-if="info.pdf_content"></div>
          <div class="report-wrap-content-box-substance no-login" v-html="info.content" v-else></div>
          <div class="report-wrap-content-box-mask" :style="{ height: maskHeight+'px' }" v-if="!info.is_buy"></div>
        </div>
        <template v-if="!info.is_buy">
          <chinese-lock :report="info" @unlock="unlockReport" @login="login" v-if="type == 'cn'"></chinese-lock>
          <english-lock :report="info" @unlock="unlockReport" @login="login" v-else-if="type == 'en'"></english-lock>
        </template>
      </div>
    </div>
    <!-- 单片报告解锁 -->
    <pay-wechat ref="payWechatRef" :language="type"></pay-wechat>
    <web-footer :language="type"></web-footer>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import PayWechat from "@/components/payWechat";
import ChineseLock from "./component/chineseLock";
import EnglishLock from "./component/englishLock";
import print from 'print-js'
import { reportDetail, reportList, reportPdf } from "@/api";
export default {
  name: "Report",
  components: {
    WebHeader,
    WebFooter,
    PayWechat,
    ChineseLock,
    EnglishLock
  },
  data() {
    return {
      loading: false,
      // 类型 cn 中文 en 英文
      type: "cn",
      // 报告详情
      info: {},
      // pdf Url
      pdf_url: "",
      // 遮罩高度
      maskHeight: 260,
      // 音频
      audioInfo: {},
      // 视频
      videoInfo: {},
    };
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      if (this.$route.params.language && this.$route.params.language == "english") {
        this.type = "en";
      } else {
        this.type = "cn";
      }
      this.paySuccessTip();
      this.getDataInfo(this.$route.params.id);
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    // 获取报告详情
    getDataInfo(msgId) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.getLoginDetail(msgId);
      } else {
        this.getNoLoginDetail(msgId);
      }
    },
    // 获取报告详情-未登录
    async getNoLoginDetail(id) {
      this.loading = true;
      let param = {
        pageSize: 1,
        currentPage: 1,
        search: `id:${id}|status:1`,
        searchFields: "id:=|status:1",
      };
      let res = await reportList(param);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.info = res.data.list[0];
          this.$nextTick(()=>{
            let con_height = window.document.getElementById("printContent").offsetHeight;
            if (con_height && con_height > 0) {
              this.maskHeight = con_height / 2;
            }
          })
        }
      }
      this.loading = false;
    },
    // 通过ID获取报告详情-已登录
    async getLoginDetail(id) {
      this.loading = true;
      let res = await reportDetail({ id: id });
      if (res && res.code == 200) {
        this.info = res.data;
        if (res.data && res.data.is_buy) {
          if (res.data.product_type == 1) {
            this.getReportPdf(id);
            this.isReportAudioOrVideo(res.data.product_detail);
          } else if (res.data.product_type == 2) {
            // 音频
            if (res.data.product_detail && res.data.product_detail.length > 0) {
              this.audioInfo = res.data.product_detail[0];
            }
          } else if (res.data.product_type == 3) {
            // 视频
            if (res.data.product_detail && res.data.product_detail.length > 0) {
              this.videoInfo = res.data.product_detail[0];
            }
          }
        }
      }
      this.loading = false;
    },
    // 获取报告pdf
    async getReportPdf(id) {
      let res = await reportPdf({ id: id });
      if (res) {
        this.pdf_url = window.URL.createObjectURL(res); // 下载的路径
      }
    },
    // 判断报告是否报告音视频
    isReportAudioOrVideo(rows) {
      if (rows && rows.length > 0) {
        let temp = rows[0];
        if (temp.product_type == 2) {
          this.audioInfo = temp;
        } else if (temp.product_type == 3) {
          this.videoInfo = temp;
        }
      }
    },
    // 打印当前页
    printFn() {
      setTimeout(function () {
        print({
          printable: 'printContent',
          type: 'html',
          scanStyles: false,
          targetStyles: ['*']
        })
      }, 500);
    },
    // 复制分享
    async copyUrl() {
      const textarea = document.createElement('textarea');
      textarea.value = window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 9999); // 为了兼容移动设备
      try {
        document.execCommand('copy');
        this.$message.success('当前地址（URL）已复制到剪贴板')
      } catch (err) {
        this.$message.error("当前地址（URL）复制失败，请手动复制分享")
      }
      document.body.removeChild(textarea);
    },
    // 解锁全文
    unlockReport() {
      let token = localStorage.getItem("cf40-token");
      if (token && this.info.id && !this.info.is_buy) {
        this.$refs.payWechatRef.open(this.info.id);
      } else {
        this.$refs.webHeaderRef.login();
      }
    },
    // 登录
    login() {
      this.$refs.webHeaderRef.login();
    },
    // PayPal支付成功提示语
    paySuccessTip() {
      if (this.$route.query.retracement && this.$route.query.retracement == 'success') {
        if (this.type == 'en') {
          this.$alert(`<p style="font-family: Georgia;">
                                PayPal payment may be delayed. If your payment has been completed and there are no changes on the page, please wait for 1 minute before refreshing and trying again</p>`,
              `Reminder`, {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'Done',
                confirmButtonClass: "confirm-button",
                callback: (action) => {
                  if (action == "confirm") {
                    window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                  }
                }
              });
        } else {
          this.$alert(`PayPal支付可能有延迟，如您支付已完成，页面无变化，请等待1分钟后刷新重试`, '温馨提示', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            confirmButtonClass: "confirm-button",
            callback: (action) => {
              if (action == "confirm") {
                window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
              }
            }
          });
        }
      }

    },
  }
}
</script>
<style lang="scss" scoped>
.en-font {
  font-family: Georgia;
}
.en-font-bold {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Regular;
}
.cn-font-bold {
  font-family: HarmonyOS_Bold;
}
.roboto-light {
  font-family: Roboto_Light;
}
.roboto-bold {
  font-family: Roboto_Bold;
}
.report {
  &-wrap {
    width: 60%;
    margin: 50px auto 80px auto;
    &-title {
      padding: 30px 40px 40px 0;
      font-size: 38px;
    }
    &-opera {
      padding: 5px 0;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-text {
        color: #747474;
        span {
          font-size: 12px;
          &:after {
            content: '|';
            padding: 0 15px;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
      &-btn {
        display: flex;
        &-download {
          cursor: pointer;
          user-select: none;
          padding: 15px 15px;
          border-left: 1px solid #dedede;
          border-right: 1px solid #dedede;
          margin-right: 20px;
          color: #ed6f33;
          display: flex;
          align-items: center;
          text-decoration: none;
          background-color: #f7f7f7;
          i {
            font-size: 28px;
            margin-right: 10px;
          }
        }
        &-oa {
          display: flex;
          justify-content: center;
          align-items: center;
          &-lump {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 20px;
            color: #0b0b0b;
            cursor: pointer;
            background-color: #f7f7f7;
            border: 1px solid #ebebeb;
            border-radius: 50%;
            &:last-child {
              margin-right: 20px;
            }
            &:hover, &.active {
              color: #fff;
              background-color: #ed6f33;
            }
          }
        }
      }
    }
    &-file {
      margin-top: 30px;
      border: 1px solid #EBEBEB;
      height: 45px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      &-name {
        font-size: 16px;
        display: inline-block;
        margin-left: 50px;
        position: relative;
      }
      audio {
        width: 250px;
        position: relative;
        margin-right: 30px;
        border: 0;
        outline: none;
      }
    }
    &-video {
      margin-top: 30px;
      text-align: center;
      video {
        width: 600px;
        height: 300px;
      }
    }
    &-content {
      position: relative;
      margin-top: 30px;
      padding-top: 30px;
      min-height: 400px;
      &-box {
        position: relative;
        &-substance {
          padding-bottom: 20px;
          ::v-deep {
            blockquote {
              margin: 10px 0;
              padding: 10px;
              display: block;
              background-color:#f5f2f0;
              border-left:8px solid #c9a63e;
            }
            img {
              max-width: 100%;
            }
          }
        }
        &-mask {
          position: absolute;
          width: 100%;
          bottom: 0;
          background: linear-gradient(to bottom, rgba(255,255,255,0.5), #ffffff);
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .report {
    &-wrap {
      width: 75%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .report {
    &-wrap {
      width: 90%;
      min-height: 300px;
      margin: 20px auto 40px auto;
      &-title {
        padding: 15px 20px 15px 0;
        font-size: 30px;
      }
      &-content {
        min-height: 300px;
      }
      &-next {
        margin-top: 50px;
        a {
          font-size: 30px;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .report {
    &-wrap {
      width: calc(100% - 30px);
      min-height: 300px;
      margin: 10px auto 20px auto;
      &-title {
        padding: 15px 20px 15px 0;
        font-size: 22px;
      }
      &-opera {
        &-text {
          span {
            font-size: 12px;
            &:after {
              padding: 0 5px;
            }
          }
        }
        &-btn {
          &-download {
            font-size: 12px;
            padding: 0px 5px;
            i {
              font-size: 22px;
              margin-right: 5px;
            }
          }
          &-oa {
            display: none;
          }
        }
      }
      &-file {
        margin-top: 30px;
        border: 1px solid #EBEBEB;
        height: inherit;
        padding: 15px 0;
        flex-direction: column;
        margin-bottom: 12px;
        &-name {
          font-size: 14px;
          margin-left: 0;
        }
        audio {
          width: revert;
          margin-top: 10px;
          max-width: 100%;
          position: relative;
          margin-right: 0;
        }
      }
      &-video {
        margin-top: 30px;
        text-align: center;
        video {
          width: 100%;
          height: 260px;
        }
      }
      &-content {
        min-height: 300px;
        margin-top: 15px;
      }
    }
  }
}
</style>
